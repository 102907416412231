import { defineStore } from 'pinia';
import { ref } from 'vue';

export type SubCategory = Omit<Category, 'subcategories'> & {
    icon: string;
};

export type Category = {
    sort: number | null;
    id: string;
    name: string;
    visible: boolean;
    subcategories: SubCategory[];
};

export const useCategoryStore = defineStore('categories', () => {
    const configStore = useConfigStore();

    const defaultCategories = ref<Category[]>([
        {
            sort: null,
            name: 'onderzoeken',
            id: '381b60f9-52d1-413d-96e8-93f0073de287',
            visible: true,
            subcategories: [
                {
                    sort: null,
                    name: 'medicatie',
                    icon: 'pill',
                    id: '0ec05275-dacd-4f63-98a4-d5644358352e',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'rontgenonderzoeken',
                    icon: 'skeleton',
                    id: '119fc8f5-87aa-45f9-a352-5764fd50bb26',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'labuitslagen',
                    icon: 'science',
                    id: '5eb11eed-cc36-4914-afcc-4221f582420c',
                    visible: true,
                },
                {
                    sort: 1,
                    name: 'overig',
                    icon: 'home_storage',
                    id: 'c64d2f60-0ca9-45af-9315-cb2f27f89158',
                    visible: true,
                },
            ],
        },
        {
            sort: null,
            name: 'specialismen',
            id: 'dee4b197-136b-49c8-b45e-bc3bc836cb8f',
            visible: true,
            subcategories: [
                {
                    sort: null,
                    name: 'kno',
                    icon: 'ent',
                    id: '149cf3c8-2594-442b-9e20-02adb355f61e',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'kindergeneeskunde',
                    icon: 'child_friendly',
                    id: '14ed446c-a124-4778-8058-03f71bd1bbad',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'mdl',
                    icon: 'gastroenterology',
                    id: '2038a80f-dd02-4121-a777-12209d022784',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'internegeneeskunde',
                    icon: 'stethoscope',
                    id: '272de643-9794-44c7-b7e0-67b597dc9f7e',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'huisartsgeneeskunde',
                    icon: 'healing',
                    id: '61ef26a5-d086-4b86-939f-1899ad118068',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'anesthesiologie',
                    icon: 'vaccines',
                    id: '63bc5cab-43ac-4d18-b692-c05241e0af13',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'klinischegenetica',
                    icon: 'genetics',
                    id: '9b4cd36f-1ec6-41af-8fb3-d93bdfb0b659',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'cardiologie',
                    icon: 'cardiology',
                    id: '9c2797e3-e466-47c0-812d-697061da353f',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'longgeneeskunde',
                    icon: 'pulmonology',
                    id: 'b39c09c3-21f5-4fa3-99fe-7ac6b5c3db0d',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'dermatologie',
                    icon: 'dermatology',
                    id: 'caa701b3-5b1a-4f3d-86b5-bd20fe74e28d',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'neurologie',
                    icon: 'neurology',
                    id: 'daa76e21-23c5-4dce-8132-e1cbddb05e1c',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'gynaecologie',
                    icon: 'gynecology',
                    id: 'e48e9950-d6d9-4b54-b673-4385bf455a18',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'oogheelkunde',
                    icon: 'visibility',
                    id: '18d0826d-0fb4-4ce8-84de-211b1fc8b5b9',
                    visible: true,
                },
                {
                    sort: null,
                    name: 'psychiatrie',
                    icon: 'psychology',
                    id: 'a8f855e8-1436-4ed8-9ecc-43b32c5cb07c',
                    visible: true,
                },
            ],
        },
    ]);

    const categories = ref<Category[]>(defaultCategories.value);

    const mergeArraysById = (arr1: any[], arr2: any[]): any[] => {
        const combined = [...arr1, ...arr2];
        const mergedMap = new Map(combined.map((obj) => [obj.id, obj]));
        return Array.from(mergedMap.values());
    };

    const mergeCategories = (defaultCategories: Category[], configCategories: Category[]) => {
        return defaultCategories.map((defaultCat) => {
            const configCat = configCategories.find((configCat) => configCat.id === defaultCat.id);

            if (configCat) {
                defaultCat.subcategories = mergeArraysById(
                    defaultCat.subcategories,
                    configCat.subcategories,
                );
            }

            return defaultCat;
        });
    };

    const mergeWithConfig = (configCategories: Category[]) => {
        categories.value = mergeCategories(defaultCategories.value, configCategories);
    };

    const findSubCategory = (subcategoryId: string) =>
        categories.value
            .map((category) => category.subcategories)
            .flat()
            .find((subcategory) => subcategory.id === subcategoryId);

    const setVisibility = (subcategoryId: string, visibility: boolean) => {
        const subcategory = findSubCategory(subcategoryId);

        if (subcategory) {
            subcategory.visible = visibility;
        }

        configStore.writeConfig({ categories: categories.value });
    };

    return { categories, findSubCategory, setVisibility, mergeWithConfig };
});
