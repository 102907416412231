import { defineStore } from 'pinia';

export type Config = {
    categories: Category[];
};

export const useConfigStore = defineStore('config', () => {
    const configFileId = ref<string | null>(null);
    const config = ref<Config | null>(null);

    const getConfigFileId = async () => {
        if (configFileId.value) return configFileId.value;

        const response = await fetch('/api/config');

        if (response.ok) {
            const files = await response.json();
            const configFile = files.find((file: any) => file.name === 'config');

            if (configFile) {
                configFileId.value = configFile.id;
                return configFile.id;
            }
        }

        return null;
    };

    const fetchConfig = async () => {
        const fileId = await getConfigFileId();

        if (fileId) {
            const response = await fetch(`/api/files/${fileId}/content`);

            if (response.ok) {
                const json = await response.json();
                config.value = json;
                return json;
            }
        }

        return config.value;
    };

    const writeConfig = async (config: Record<string, any>) => {
        return await fetch('/api/config', {
            method: 'POST',
            body: JSON.stringify(config),
        });
    };

    const createConfigFile = async () => {
        return await writeConfig({ categories: [] });
    };

    return {
        config,
        configFileId,
        createConfigFile,
        getConfigFileId,
        fetchConfig,
        writeConfig,
    };
});
